<template>
    <b-button
        class="btn btn-pdv btn-pdv-blue"
        :disabled="busy"
        @click="$emit('onClick')"
    >
        {{ getI18n('COMMON.clean') }} {{ getI18nModified({ prefix: 'COMMON.filter', modifier: 2 }) }}
    </b-button>
</template>

<script>
    export default {
        props: {
            busy: {
                type: Boolean,
                required: false,
            },
        },
}
</script>
