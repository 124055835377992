<script>
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import DeleteButton from '@/src/components/DeleteButton'
import CleanButton from '@/src/components/ClearButton'
import CancelLabelButton from '@/src/components/CancelLabelButton.vue'
import { startCase } from 'lodash'

const i18nCommon = 'COMMON'
const i18nResponses = 'RESPONSES'

export default {
    components: {
        AddButton,
        SaveButton,
        DeleteButton,
        CleanButton,
        CancelLabelButton,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: '',
        },
        message: {
            type: String,
            required: false,
            default: '',
        },
        busy: {
            type: Boolean,
            required: false,
        },
        operation: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            cancelOperation: false,
            keepAdding: false,
            id: '',
            handleSubmit: () => {}
        }
    },
    computed: {
        i18nDescriptionModal() {
            if (this.description) {
                return this.description
            }
            else if (this.operation === 'created') {
                if (this.cancelOperation) {
                    return `${this.getI18n(i18nCommon, 'cancel')} ${this.getI18n(i18nCommon, 'creation').toLowerCase()} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.title.toLowerCase()}`
                }
                return `${this.getI18n(i18nCommon, 'add')} ${this.title.toLowerCase()}`
            }
            else if (this.operation === 'edited') {
                if (this.cancelOperation) {
                    return `${this.getI18n(i18nCommon, 'cancel')} ${this.getI18n(i18nCommon, 'edition').toLowerCase()} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.title.toLowerCase()}`
                }
                return `${this.getI18n(i18nCommon, 'edit')} ${this.title.toLowerCase()}`
            }
            else if (this.operation === 'deleted') {
                return `${this.getI18n(i18nCommon, 'delete')} ${this.title.toLowerCase()}`
            }
            else if (this.operation === 'delete_all') {
                return `${this.getI18n(i18nCommon, 'delete_all')} ${this.title.toLowerCase()}`
            }
            else if (this.operation === 'delete_selected') {
                return `${this.getI18n(i18nCommon, 'delete')} ${this.title.toLowerCase()} ${this.getI18nModified({
                    prefix: i18nCommon,
                    suffix: 'selected',
                    modifier: 2,
                }).toLowerCase()}`
            }
            else if (this.operation === 'import') {
                if (this.cancelOperation) {
                    return `${this.getI18n(i18nCommon, 'cancel')} ${this.getI18n('IMPORT_JOBS', 'TITLES.import_job').toLowerCase()} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.title.toLowerCase()}`
                }
                return `${this.getI18n('IMPORT_JOBS', 'TITLES.import_job')} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.title.toLowerCase()}`
            }
            else if (this.operation === 'export') {
                if (this.cancelOperation) {
                    return `${this.getI18n(i18nCommon, 'cancel')} ${this.getI18n('EXPORT_JOBS', 'TITLES.export').toLowerCase()} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.title.toLowerCase()}`
                }
                return `${this.getI18n('EXPORT_JOBS', 'TITLES.export')} ${this.getI18n(i18nCommon, 'from').toLowerCase()} ${this.title.toLowerCase()}`
            }
            else if (this.operation === 'clean') {
                return `${this.getI18n(i18nCommon, 'clean')} ${this.title.toLowerCase()}`
            }
            else {
                return ''
            }
        },
        i18nMessageModal() {
            if (this.message) {
                return this.message
            }
            if (this.operation === 'created') {
                return this.cancelOperation ? this.getI18n(i18nResponses, 'without_create_warning_text') : this.getI18n(i18nResponses, 'add_confirmation_text')
            }
            else if (this.operation === 'edited') {
                return this.cancelOperation ? this.getI18n(i18nResponses, 'without_saving_warning_text') : this.getI18n(i18nResponses, 'edit_confirmation_text')
            }
            else if (this.operation === 'deleted') {
                return this.getI18n(i18nResponses, 'delete_confirmation_text')
            }
            else if (this.operation === 'delete_all' || this.operation === 'delete_selected') {
                return this.getI18nModified({
                    prefix: i18nResponses,
                    suffix: 'delete_confirmation_text',
                    modifier: 2,
                })
            }
            else if (this.operation === 'clean') {
                return this.getI18n(i18nResponses, 'clean_filter_confirmation_text')
            }
            else {
                return this.getI18n(i18nResponses, 'confirmation_text')
            }
        },
        i18nDiscardChanges() {
            if (this.operation === 'created') {
                return `${this.getI18n(i18nCommon, 'discard')} ${startCase(this.getI18n('PAGINATION', 'records'))}`
            }
            else if (this.operation === 'edited') {
                return `${this.getI18n(i18nCommon, 'discard')} ${this.getI18nModified({
                    prefix: i18nCommon,
                    suffix: 'change',
                    modifier: 2,
                })}`
            }
            else if (this.operation === 'import') {
                return `${this.getI18n(i18nCommon, 'discard')} ${this.getI18n('IMPORT_JOBS', 'TITLES.import_job')}`
            }
            else {
                return this.getI18n(i18nCommon, 'cancel')
            }
        }
    },
    beforeMount() {},
    mounted() {},
    methods: {
        showModal(cancelOperation, handleSubmit, id, keepAdding) {
            this.cancelOperation = cancelOperation
            this.handleSubmit = handleSubmit
            this.id = id
            this.keepAdding = keepAdding
            this.$refs.confirmationModal.show()
        },
        handleOk(confirmation) {
            this.handleSubmit(confirmation, this.id);
            this.$refs.confirmationModal.hide()
        },
    },
}
</script>
<template>
    <div>
        <b-modal
            id="confirmationModal"
            ref="confirmationModal"
            centered
            :no-close-on-backdrop="true"
            :no-enforce-focus="true"
            size="md"
        >
            <b-row class="modal-body description">
                {{ i18nDescriptionModal }}
            </b-row>
            <b-row class="modal-body message">
                {{ i18nMessageModal }}
            </b-row>
            <template v-slot:modal-header>
                <h4 class="modal-title">
                    {{ title }}
                </h4>
                <button class="close" @click="handleOk(false)">
                    <i class="fe-x-circle icon-blue"/>
                </button>
            </template>
            <template v-slot:modal-footer>
                <div v-if="cancelOperation" class="col text-center ml-3 mr-3">
                    <delete-button
                        class="mb-1"
                        :busy="busy"
                        :title="i18nDiscardChanges"
                        @onClick="handleOk(true)"
                    />
                    <cancel-label-button
                        v-if="!busy"
                        @onClick="handleOk(false)"
                    />
                </div>
                <div v-else class="col text-center ml-3 mr-3">
                    <add-button
                        v-if="operation === 'created'"
                        class="mb-1"
                        :busy="busy"
                        @onClick="handleOk(true)"
                    />
                    <add-button
                        v-else-if="operation === 'clone'"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON.clone')"
                        @onClick="handleOk(true)"
                    />
                    <add-button
                        v-else-if="operation === 'copy'"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON.copy')"
                        @onClick="handleOk(true)"
                    />
                    <save-button
                        v-else-if="operation === 'edited'"
                        class="mb-1"
                        :busy="busy"
                        @onClick="handleOk(true)"
                    />
                    <delete-button
                        v-else-if="operation === 'deleted'"
                        class="mb-1"
                        :busy="busy"
                        @onClick="handleOk(true)"
                    />
                    <delete-button
                        v-else-if="operation === 'delete_all'"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON.delete_all')"
                        @onClick="handleOk(true)"
                    />
                    <delete-button
                        v-else-if="operation === 'delete_selected'"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON.delete_selected')"
                        @onClick="handleOk(true)"
                    />
                    <clean-button
                        v-else-if="operation === 'clean'"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON.clean')"
                        @onClick="handleOk(true)"
                    />
                    <save-button
                        v-else-if="operation === 'import'"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON', 'import')"
                        @onClick="handleOk(true)"
                    />
                    <save-button
                        v-else-if="operation === 'export'"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON', 'export')"
                        @onClick="handleOk(true)"
                    />
                    <save-button
                        v-if="operation === 'created' && keepAdding"
                        class="mb-1"
                        :busy="busy"
                        :title="getI18n('COMMON', 'add_and_create_another')"
                        @onClick="handleOk('create_another')"
                    />
                    <cancel-label-button
                        v-if="!busy"
                        @onClick="handleOk(false)"
                    />
                </div>
            </template>
        </b-modal>
    </div>
</template>
